import axios from 'axios'
import config from '../config';



const requestapi = axios.create({
    baseURL: 'https://'+config.API_URL
});

//requestapi.defaults.baseURL = config.API_URL;
//requestapi.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
//requestapi.defaults.headers.post['Content-Type'] = 'application/json';
requestapi.defaults.timeout = 2500;

const post = (endpoint,data,option) => {
    return requestapi.post(`${endpoint}`,data,option)
}

const del = (endpoint,data,option) => {
    return requestapi.delete(`${endpoint}`,data,option)
}

const get = (endpoint,option) => {
    return requestapi.get(`${endpoint}`,option)
}

export default {
    post,
    get,
    del
}

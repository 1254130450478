<template>
  <div class="leading-normal tracking-normal" id="main-body">
    <div class="h-screen flex justify-center">

      <div class="container flex justify-center items-center flex-wrap h-full px-6 py-12 " id="main-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>

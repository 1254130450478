import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'

import Dashboard from '@/components/Dashboard'
import Home from '@/pages/Home'
import Table from '@/pages/Table'
import Analytics from '@/pages/Analytics'


import User from '@/components/User'
import Login from '@/pages/Login'
import Register from '@/pages/Register'

import store from './store'

import '@/assets/css/tailwind.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faCheck, faXmark, faEdit, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret)
library.add(faCheck)
library.add(faXmark)
library.add(faEdit)
library.add(faPen)
library.add(faTrash)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(Router)

const routes = [
  { 
    path: '/', 
    redirect: { name: 'Home' }
  },{ 
    path: '/dashboards', 
    component: Dashboard,
    meta: {requiresAuth: true},
    children: [
      { path: '/', redirect: { name: 'Home' },meta: {requiresAuth: true} },
      { path: 'team', name: 'Home', component: Home,meta: {requiresAuth: true} },
      { path: 'analytics', name: 'Analytics', component: Analytics,meta: {requiresAuth: true} },
      { path: ':dashboard', name: 'dashboard', component: Table ,meta: {requiresAuth: true} },

    ]
  },
  { 
    path: '/user', 
    component: User,
    children: [
      { path: '/', redirect: { name: 'Login' } },
      { path: 'login', name: 'Login', component: Login },
      { path: 'register', name: 'Register', component: Register },
      //{ path: 'account', name: 'Account', component: Account,meta: {requiresAuth: true} }
    ]
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(r => r.meta.requiresAuth)) {
    if (!store.getters.token) {
      if (localStorage.getItem("token")){
        store.commit("setToken",localStorage.getItem("token"))
        next() 
      }else{
        next({ name: 'Login' })
      }
    } else {
      next() 
    }
  } else {
    next() 
  }
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')


<template>
    <div id="login" class="max-w-xl">
      <div class="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
        <div class="w-8/12 mb-0">
          <img
            src="/assets/draw2.svg"
            class="w-full"
            alt="Phone image"
          />
        </div>
        <div class="w-8/12 mt-2">
          <form v-on:submit.prevent="onSubmit">
            <!-- Email input -->
            <div class="mb-4">
              <input
                type="text"
                name="email"
                class="form-control block w-full px-4 py-2 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Email address"
                required
              />
            </div>

            <!-- Password input -->
            <div class="mb-4">
              <input
                type="password"
                name="password"
                class="form-control block w-full px-4 py-2 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Password"
                required
              />
            </div>

            <div class="flex justify-between flex-col items-center mb-6">
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  name="remember"
                  class="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  id="exampleCheck3"
                  checked
                />
                <label class="form-check-label inline-block text-gray-800" for="exampleCheck2"
                  >Remember me</label
                >
              </div>
              <a
                href="#!"
                class="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out"
                >Forgot password?</a
              >
            </div>

            <span class="inline-block text-red-600">{{error}}</span>
            <!-- Submit button -->
            <button
              type="submit"
              class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              Sign in
            </button>
            <div class="mt-2">
              <a
                href="register"
                class="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out"
                >I'm a newbie. Create an account</a
              >
            </div>

          </form>
        </div>
        </div>
    </div>
</template>

<script>
import request from '../utils/request'
import { mapState } from 'vuex'

export default {
    name: 'Login',
    computed: {
      ...mapState(['token'])
    },
    data() {
      return {
        data: null,
        error: '',        
      }
    },
    mounted () {

    },
    methods: {
      onSubmit: function (event) {
        if (event) {
          event.preventDefault()
          let data = {
            email: event.target.elements.email.value,
            password: event.target.elements.password.value,
            remember: event.target.elements.remember.checked,
          }
          request.post("/login",data,{"Content-Type":'application/json'})
            .then(response => {
              if (response && response.data && response.data.status == "success" && response.data.token){
                this.$store.commit("setToken",response.data.token)
                if (data.remember){
                  localStorage.setItem('token', response.data.token)
                }
                this.$router.push({ name: 'Home' })
              }else{
                this.error = response.data.message
                event.target.elements.password.value = ""
              }
              
            }).catch( err => {
              this.error = err?.response?.data?.message || err.message || "Internal Error"
              event.target.elements.password.value = ""
            })
        }
      }
    }
}
</script>
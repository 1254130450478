<template>
  <div id="table">

          <!-- breadcrumb -->
          <nav class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
            <ol class="list-none p-0 inline-flex">
              <li class="flex items-center text-blue-500">
                <a class="text-gray-700">Team</a>
                <svg class="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
              </li>
              <li class="flex items-center">
                <router-link to="Team">
                  <a class="text-gray-600">Dashboards</a>
                </router-link>
              </li>
            </ol>
          </nav>
          <!-- breadcrumb end -->
          <div v-if="loading" class="flex justify-center items-center space-x-2">
              <svg class="w-12 h-12 animate-spin text-indigo-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4.75V6.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M19.25 12L17.75 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M12 17.75V19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.25 12L4.75 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
          </div>

          <!-- ERROR -->
          <div v-if="error_message" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
            <p class="font-bold">Be Warned</p>
            <p>{{error_message}}</p>
          </div>
          
          <div v-if="team && !loading" class="lg:flex justify-between items-center mb-6">
            <p class="text-2xl font-semibold mb-2 lg:mb-0">Team <span class="text-blue-500">{{team.name}}</span> !</p>
            <p class="text-lg  mb-2 lg:mb-0">Team code: <span class="font-semibold">{{team.code}}</span></p>
            <button v-if="!createDash" @click="createDash = !createDash" class="bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">Nouveau dashboard</button>
            <button v-if="createDash" @click="createDash = !createDash" class="bg-red-500 hover:bg-red-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">cancel</button>

          </div>

          <div v-if="team && !loading && createDash" class="w-full bg-white border text-blue-400 rounded-lg px-6 py-3 mb-4 xl:mb-0">
            <div class="text-gray-700 flex justify-between content-center ">
              <div class="flex-1 text-left mr-2">
                <input 
                  v-model="label"
                  placeholder="Name"
                  class="mb-1 form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"/>
                <input 
                  v-model="description"
                  placeholder="Description"
                  class=" form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"/>
              </div>
              <div class="text-gray-700 text-right self-center"> 
                <button @click="createDashboard()" class="bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">Créer</button>
              </div>
            </div>
          </div>


          <div v-if="!team && !loading" class="lg:flex justify-between items-center mb-6">
            <p class="text-2xl font-semibold mb-2 lg:mb-0">Bonjour!</p>
          </div>
          
          <div v-if="!team && !loading" class="lg:flex lg:flex-col items-left mb-6">
            <span>Créer une team:</span>
            <div class="flex flex-col sm:flex-row sm:mx-6">
              <input 
                v-model="teamname"
                placeholder="name"
                class=" form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"/>
                <button @click="createTeam()" class="sm:ml-4 bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">Créer</button>
            </div>
          </div>

          <div v-if="!team && !loading" class="lg:flex lg:flex-col mb-6 ">
            <span>Rejoindre une team:</span>
            <div  class="flex flex-col sm:flex-row sm:mx-6">
              <input 
                v-model="code"
                placeholder="CODE"
                class=" form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"/>
              <button @click="joinTeam()" class="sm:ml-4 bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">Rejoindre</button>
            </div>
          </div>

          <div v-if="team && !loading" class="flex flex-wrap -mx-3 mb-6" >
            <div v-for="dashboard in dashboards" class="w-full px-3" :key="dashboard.id">
              <router-link :to="''+dashboard.id">
                <button class="w-full bg-white hover:bg-gray-100  hover:border-gray-800 border text-blue-400 rounded-lg px-6 py-3 mb-4">
                  <div class="text-gray-700  flex justify-between">
                    <div class="text-left">
                      <p class="font-semibold text-2xl">{{dashboard.name}}</p>
                      <p>{{dashboard.description}}</p>
                    </div>
                    <div class="text-gray-700 text-right"> 
                      <p class="font-semibold text-lg">Total dépense:</p>
                      <p class="text-red-500 text-lg">{{Number(dashboard.total).toFixed(2)*-1}}€</p>
                    </div>
                  </div>
                </button>
              </router-link>
            </div>
          </div>

  </div>
</template>

<script>
import request from '../utils/request'

export default {
  name: 'Home',
  data() {
      return {
          dashboards: [],
          createDash: false,
          error_message: "",
          team: null,
          loading: true,
          teamname: "",
          code: "",
          label:"",
          description:""
      }
  },
  mounted() {
    this.loadTeam()
  },
  methods: {
    loadDashboard() {
      request.get("/dashboards",{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
        .then(response => {
          if (response && response.data ){
            this.error_message = ""
            this.dashboards = response.data
            this.loading = false
          }
        }).catch( err => {
          this.error_message = err?.response?.data?.message || err.message || "Internal Error"
        })
    },
    createDashboard() {
      this.error_message = ""
      if (this.label && this.description){
        let data = { nom: this.label, description: this.description}
        request.post("/dashboard/create",data,{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data && response.data.status == "success"){
              this.createDash = false
              this.label = ""
              this.description = ""
              this.error_message = ""
              this.loadDashboard()
            }else{
              this.error_message = response.data.message
            }
          }).catch( err => {
            this.error_message = err?.response?.data?.message || err.message || "Internal Error"
          })
      }
    },
    loadTeam() {
      request.get("/team",{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
        .then(response => {
          if (response && response.data && response.data.length){
            this.error_message = ""
            this.team = response.data[0];
            this.loadDashboard()
          }else if (response && response.data && !response.data.length){
            this.loading = false
          }
        }).catch( err => {
          this.error_message = err?.response?.data?.message || err.message || "Internal Error"
        })
    },
    createTeam() {
      this.error_message = ""
      if (this.teamname){
        let data = { name: this.teamname}
        request.post("/team/create",data,{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data && response.data.status == "success"){
              this.error_message = ""
              this.loadTeam()
            }else{
              this.error_message = response.data.message
            }
          }).catch( err => {
            this.error_message = err?.response?.data?.message || err.message || "Internal Error"
          })
      }
    },

    joinTeam() {
      this.error_message = ""
      if (this.code){
        let data = { code: this.code}
        request.post("/team/join",data,{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data && response.data.status == "success"){
              this.error_message = ""
              this.loadTeam()
            }else{
              this.error_message = response.data.message
            }
          }).catch( err => {
            this.error_message = err?.response?.data?.message || err.message || "Internal Error"
          })
      }
    },
  }
}
</script>